<template>
  <v-dialog v-model="input" persistent max-width="300">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat v-if="declineText" @click="clicked(false)">{{ declineText }}</v-btn>
        <v-btn flat @click="clicked(true)">{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseConfirmModal',
  props: {
    value: Boolean,
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Continue',
    },
    declineText: {
      type: String,
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    clicked(accepted) {
      this.input = false;
      this.$emit('clicked', accepted);
    },
  },
};
</script>
